@mixin transition{
    transition: all .3s ease-in-out;
}

@mixin bold-text{
    color: white;
    font-size: 16px;
    font-weight: 700;
}

@mixin center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}