body{
    /*font-family: Ubuntu, serif;*/
    overflow-x: hidden;
}

.navbar-expand .navbar-nav .nav-link {
    color: gray !important;
}

.text-theme-light{

}

.nav-link{
    text-decoration: none;
    color: white !important;
}
.para,h4{
    /*color: #0061aa !important;*/
    /*color: #014375 !important;*/
    font-weight: bolder !important;
}
.bg-image {
    /*background-image: url(../img/_slide01.png) !important;*/
    /*background-color: #033973 !important;*/
    /*background-color: #0560a8 !important;*/
    /*background-color: #293a4a !important;*/
    background-color: #f9f9f9!important;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

table, tr, td{
    text-align: center;
}
/*
.card-header{
    background-color:#03355a !important;
}
.card-header h4{
    color: white !important;
}
*/

.custom-card {
    
    background: transparent !important;
    /*background-color: rgba(67, 67, 67, 0.82); */
    /* Réglez l'opacité selon vos préférences */
    padding: 20px; /* Ajoutez un espacement intérieur si nécessaire */
    /**border-bottom: 9px solid #011b2f;
    border-right: 6px solid #011b2f;**/
}


.bg-theme{
    /*background-image: linear-gradient(90deg, $theme-color, $theme-color2,$theme-color3);*/
    /*background-image: linear-gradient(90deg, #065947, #05d3a7, #088b6f) !important;*/
    /*background-color: #08193e !important;*/
    background-color: #fff!important;
    box-shadow: 0 2px 20px #0003;
}

.text-silver{
    color: $silver;
}

.text-theme-light{
    color: $theme-color-light !important;
}

.bg-theme-basic{
    /**background: $theme-color;**/
    /*background-image: linear-gradient(130deg, $theme-color, $theme-color2,$theme-color3);*/
    /*background-image: linear-gradient(1deg, #122622, #05d3a7, #088b6f) !important;*/
    background-color: #001051 !important;
}

p, h2, h2, h3, h4, h5, h6, address, ul{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
}

.nav-link{
    color: white;
}

.nav-link:hover{
    color: white;
    &:hover{
        color: $silver;
    }
    &:focus{
        color: $silver;
    }
}

.sb-sidenav-menu-heading{
    color: $theme-color-light;
}

.modal-header{
    padding: .25rem 1rem;
    /*background-image: linear-gradient(90deg, $theme-color, $theme-color-light,$theme-color);*/
    background-color: #112752 !important;
    color: white;
}

.section-gap{
    margin-top: 100px;
    margin-bottom: 100px;
}

/*
.section-headline{
    color: $theme-color;
    margin-bottom: 50px;
}
*/


.text-justify{
    text-align: justify;
}


/*
.bottom-nav{
    padding: 0;
    text-align: center;
}
*/


.zoom-able{
    transform: all .3s ease-in-out;
    &:hover{
        transform: scale(1.05);
    }
}

.aspect-one{
    aspect-ratio: 1/1 !important;
    object-fit: cover;
}
.aspect-two{
    aspect-ratio: 1/1.25 !important;
}

.text-theme{
    /*color: $theme-color;*/
}

.btn3{
    background-color: #0ea518 !important;
    border-color: #0ea518 !important;
    color: white;
  }

  .btn31,.btn31:hover{
    background-color: #112752 !important;
    border-color: #112752 !important;
    color: white;
  }

  .btn3:hover{
    background-color: #0ea518 !important;
    border-color: #0ea518 !important;
    color: white;
  }

.btn4{
    /*background-color: #071a3f !important;*/
    background-color: #0ea518 !important;
    border-color: #0ea518 !important;
    color: white;
    font-size: 18px !important;
    font-weight: bolder;    
  }

  .btn4:hover{
    background-color: #071a3f !important;
    border-color: #071a3f !important;
    color: white;
  }

.theme-button3{
    /*background: $theme-color;*/
    background-color:  #44abb6 !important;
    border-color:  #44abb6 !important;
    font-size: 18px !important;
    font-weight: bolder;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background-color:  #054874 !important;
        border-color:   #054874 !important;
    }
}

.add-files{
    /*background: $theme-color;*/
    background-color:  #000f51 !important;
    border-color:  #000f51 !important;
    font-size: 16px !important;
    font-weight: bolder;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background-color:  #000f51 !important;
        border-color:   #000f51 !important;
    }
}

.theme-button2{
    /*background: $theme-color;*/
    background-color:  #44abb6 !important;
    border: 1px solid #44abb6;
    font-size: 18px !important;
    font-weight: bolder;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background: #44abb6 !important;
        border: 1px solid $theme-color;
    }
}

.theme-button{
    /*background: $theme-color;*/
    background-color: #071a3f !important;
    border: 1px solid $theme-color;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background: $white !important;
        border: 1px solid $theme-color;
    }
}

label{
    position: relative;
    .login-error-msg{
        position: absolute;
        color: red;
        text-shadow: 0 0 8px $white;
    }
}


.my-table{
    font-size: 14px;
    th, td{
        vertical-align: middle;
    }
}

.table-image{
    width: 75px;
    cursor: pointer;
}


.loader{
    width: 100%;
    height: 250px;
    position: relative;
    img{
        position: absolute;
        @include center;
    }
}

.soft-landing{
 @include transition;
}

.no-data-found-container{
    width: 100%;
    min-height: 100px;
    .no-data-found{
        position: absolute;
    }
}

/* Ajoutez ces styles à votre fichier CSS */


.bg-warning2 {
    --bs-bg-opacity: 1;
    background-color: rgb(255 255 255) !important;
    border: 1px solid #cecece;
  }
  
.bg-success2 {
    --bs-bg-opacity: 1;
    background-color: #0ba617 !important;
    border: 1px solid #cecece;
    color:white;
    font-weight: bolder !important;
  }
  
  .bg-info2 {
    --bs-bg-opacity: 1;
    color: white;
    font-size: 14px !important;
    background-color: rgb(89 89 89) !important;
}
  .bg-info3 {
    --bs-bg-opacity: 1;
    color: white;
    font-size: 14px !important;
    background-color: #03A9F4 !important;
}

.bg-info4 {
    --bs-bg-opacity: 1;
    color: white;
    font-size: 14px !important;
    background-color: #6d8591 !important;
}

.text-primary{
    color: white !important;
}

.text-primary2{
    color: #fff !important;
}


.card-header:first-child {
    border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
    background: #000f51;
}


.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgb(0 15 81) !important;
}