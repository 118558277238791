.survol {
    /* Vos styles CSS ici */
    /* Exemple de style */
    
    
    padding: 10px;
}


.survol:hover {
    /* Vos styles CSS ici */
    /* Exemple de style */
    background-color: #efeeee;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
}


.sb-sidenav .sb-sidenav-footer{
    background-color: #001051 !important;
    box-shadow: none;
}


