$theme-color: #05d3a7;
$theme-color2: #05d3a7;
$theme-color3: #088b6f;
$theme-color-light: #0061aa;
$theme-color-red: #dc2a13;
$white: white;
$silver: silver;

/*
linear-gradient(90deg, #05d3a7, #05d3a7, #088b6f)
background-color: #033973 !important;
linear-gradient(90deg, #3F51B5, #0061aa, #3F51B5)
$theme-color: #662999;
$theme-color-light: #a22bff;
$theme-color-red: #dc2a13;
$white: white;
$silver: silver;

*/